import Marlboro from "../../assets/TobacoLogo/1.png";
import MarlboroMenhol from "../../assets/TobacoLogo/2.png";
import MarlboroBlack from "../../assets/TobacoLogo/3.png";
import MarlboroBlend from "../../assets/TobacoLogo/4.png";
import Marlboro100s from "../../assets/TobacoLogo/5.png";
import CamelBlue from "../../assets/TobacoLogo/6.png";
import kool from "../../assets/TobacoLogo/14.png";
import newPort from "../../assets/TobacoLogo/8.png";
import parliament from "../../assets/TobacoLogo/9.png";
import luckySrike from "../../assets/TobacoLogo/10.png";
import camelRush from "../../assets/TobacoLogo/11.png";
import luckySrikeSpirit from "../../assets/TobacoLogo/15.png";
import benson from "../../assets/TobacoLogo/13.png";


export const tobacooList = [
    {
      img: `${Marlboro}`,
      para : "MARLBORO"
      
    },
    {
      img: `${MarlboroMenhol}`,
      para : "MARLBORO MENHOL"
      
    },
    {
      img: `${MarlboroBlack}`,
      para : "MARLBORO BLACK"

      
    },
    {
      img: `${MarlboroBlend}`,
      para : "SPECIAL BLEND"

      
    },
    {
      img: `${Marlboro100s}`,
      para : "MARLBORO 100S"
      
    },
    {
      img: `${CamelBlue}`,
      para : "CAMEL BLUE"

      
    },
    {
      img: `${kool}`,
      para : "KOOL"
      
      
    },
    {
      img: `${newPort}`,
      para : "NEWPORT"

      
    },
    {
      img: `${parliament}`,
      para : "PARLIAMENT"

      
    },
    {
      img: `${luckySrike}`,
      para : "LUCKY STRIKE"

      
    },
    {
      img: `${camelRush}`,
      para : "CAMEL RUSH"

      
    },
    {
      img: `${luckySrikeSpirit}`,
      para : "LUCKY STRIKE"

      
    },
    {
      img: `${benson}`,
      para : "BENSON & HEDGES"

      
    },
   
]
