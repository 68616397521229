import joblink from "../../assets/Careers/joblink.svg"
export const new_jobs = [
    {
        src: `${joblink}`,
        job_title: "Food Service Opportunities",
        job_description: "Line Cook, Houston TX.",
        url: "https://nowandforever.com/jobs/food-service-opportunities/"
    },
    {
        src: `${joblink}`,
        job_title: "Associate Opportunities",
        job_description: "Day Shift, Night Shift, Stocker, Houston TX.",
        url: "https://nowandforever.com/jobs/associate-opportunities/"
    },
    {
        src: `${joblink}`,
        job_title: "Supervisor/Manager Opportunities",
        job_description: "Manager, Supervisor, Houston TX.",
        url: "https://nowandforever.com/jobs/supervisor-manager-opportunities/"
    },
];