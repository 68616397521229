import img1 from "../../assets/Merchandise/merch-img-1.png"
import img2 from "../../assets/Merchandise/merch-img-2.png"
import img3 from "../../assets/Merchandise/merch-img-3.png"
import img4 from "../../assets/Merchandise/merch-img-4.png"
import img5 from "../../assets/Merchandise/merch-img-5.png"


export const merchData = [
    {
         img : `${img1}`,
         item : "N&F Sling",
        link : 'https://now-n-forever-mean3.myshopify.com/products/n-f-sling'
    },
    {
        img : `${img2}`,
        item : "N&F 3 BULL BEANIE",
        link : 'https://now-n-forever-mean3.myshopify.com/products/n-f-3-bull-beanie'
   },
   {
    img : `${img3}`,
    item : "N&F AirFreshners",
    link : 'https://now-n-forever-mean3.myshopify.com/products/n-f-airfreshners'
},
{
    img : `${img4}`,
    item : "Valentines Day LOVE TRUCK - WHITE",
    link : 'https://now-n-forever-mean3.myshopify.com/products/valentines-day-love-truck-white'
},
{
    img : `${img5}`,
    item : "Valentines Day TE QUIERO MOOCHO - BLACK",
    link : 'https://now-n-forever-mean3.myshopify.com/products/valentines-day-te-quiero-moocho-black'
},

]