import logo from '../../assets/main-logo.svg'
import logo_shopee from '../../assets/shopee_logo.png'

export const branches = [
  { id: 1, name: 'Beechnut Site #2', lat: 29.6891824447344, lng: -95.62275621819154, address: '13600 Beechnut St Houston, Texas , 77083', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13600+Beechnut+St,+Houston,+TX+77083,+USA/@29.6889681,-95.6227884,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dd94f256d777:0x94911286e5225a1!2m2!1d-95.6227884!2d29.6889681?entry=ttu' },
  { id: 2, name: 'Richmond', lat: 29.73016117441898, lng: -95.60515773353457, address: '3151 S Dairy Ashford Rd Houston, Texas, 77082', src: `${logo}`, loc: 'https://www.google.com/maps/dir//3151+S+Dairy+Ashford+Rd,+Houston,+TX+77082,+USA/@29.7300494,-95.605147,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dc5841b59f75:0x1c4b62f27bf158e3!2m2!1d-95.605147!2d29.7300494?entry=ttu'},
  { id: 3, name: 'Channelview Site #3', lat: 29.78940316387135, lng: -95.09149048935112, address: '17124 East Freeway Channelview, Texas, 77530', src: `${logo}`, loc: 'https://www.google.com/maps/dir//17124+E+Freeway+Service+Rd,+Channelview,+TX+77530,+USA/@29.7892449,-95.0915334,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640a72dfcf2c083:0x9e2ef28311e240a8!2m2!1d-95.0915334!2d29.7892449?entry=ttu'},
  { id: 4, name: '3 B’s Bagels Bread & Butter 1585 HWY 6 S', lat: 29.7564406585327, lng: -95.64393769120576, address: '1585 HWY-6 South Houston, TX, 77077', src: `${logo}`, loc: 'https://www.google.com/maps/dir//1585+S+Texas+6,+Houston,+TX+77077,+USA/@29.7562544,-95.6439055,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dc018feaf1df:0xc2261623ebadb4d9!2m2!1d-95.6439055!2d29.7562544?entry=ttu'},
  { id: 5, name: 'Coastal Mart Site #4', lat: 29.715754441908214, lng: -95.58295517586278, address: '11611 Alief Clodine Rd Houston, TX, 77082', src: `${logo_shopee}`, loc: 'https://www.google.com/maps/dir//11611+Alief+Clodine+Rd,+Houston,+TX+77082,+USA/@29.7155122,-95.5829659,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dd1f87ababb9:0x1938ed7be946bc80!2m2!1d-95.5829659!2d29.7155122?entry=ttu'},
  { id: 6, name: 'Westheimer Site #7', lat: 29.735567648970335, lng: -95.63469629120665, address: '13905 Westheimer Rd Houston, TX , 77077', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13905+Westheimer+Rd,+Houston,+TX+77077,+USA/@29.7354093,-95.6346641,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dc3cd0b8a473:0xfd14dfb9703a9fc!2m2!1d-95.6346641!2d29.7354093?entry=ttu'},
  { id: 7, name: 'Bissonnet Site #10', lat: 29.681446113240522, lng: -95.6227202047004, address: '13646 Bissonnet St Houston, Texas , 77083', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13905+Westheimer+Rd,+Houston,+TX+77077,+USA/@29.7354093,-95.6346641,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dc3cd0b8a473:0xfd14dfb9703a9fc!2m2!1d-95.6346641!2d29.7354093?entry=ttu'},
  { id: 8, name: 'Parker Site #9', lat: 29.859414871375037, lng: -95.40150201818435, address: '251 W Parker Houston, Texas , 77076', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13646+Bissonnet+St,+Houston,+TX+77083,+USA/@29.681297,-95.6227202,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dd8d7d207219:0x70628f392323864b!2m2!1d-95.6227202!2d29.681297?entry=ttu'},
  { id: 9, name: 'Breaktime', lat: 29.7133178295528, lng: -95.47601980469904, address: '6225 Chimney Rock Houston, Texas, 77081', src: `${logo}`, loc: 'https://www.google.com/maps/dir//6225+Chimney+Rock+Rd,+Houston,+TX+77081,+USA/@29.7131874,-95.4760198,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640c187a4463707:0x1b57608c008b63fe!2m2!1d-95.4760198!2d29.7131874?entry=ttu'},
  { id: 10, name: 'Breaktime Harwin', lat: 29.717613159535667, lng: -95.53861234887978, address: '9995 Harwin Houston, Texas, 77036', src: `${logo}`, loc: 'https://www.google.com/maps/dir//9995+Harwin+Dr.,+Houston,+TX+77036,+USA/@29.7174641,-95.5385587,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640c2e051cc32f9:0xf630de3945b4ff60!2m2!1d-95.5385587!2d29.7174641?entry=ttu'},
  { id: 11, name: 'Laterna', lat: 29.686027389307117, lng: -95.64278542004492, address: '8559 Highway 6 South Houston, Texas , 77083', src: `${logo}`, loc: 'https://www.google.com/maps/dir//8559+S+Texas+6,+Houston,+TX+77083,+USA/@29.6857536,-95.6432711,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dde22d1365a9:0x49cd8f1754e7422a!2m2!1d-95.6432711!2d29.6857536?entry=ttu'},
  { id: 12, name: 'Texas Forever Cafe Express @ Beechnut', lat: 29.6891824447344, lng: -95.62286350655334, address: '13600 Beechnut St 7081', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13600+Beechnut+St,+Houston,+TX+77083,+USA/@29.6889681,-95.6227884,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dd94f256d777:0x94911286e5225a1!2m2!1d-95.6227884!2d29.6889681?entry=ttu'},
  { id: 13, name: 'Bella Mart Inc', lat: 29.99058002931476, lng: -95.43150281817876, address: '15303 Ella Blvd Houston, Texas , 77090', src: `${logo}`, loc: 'https://www.google.com/maps/dir//15303+Ella+Blvd,+Houston,+TX+77090,+USA/@29.9904035,-95.431535,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640ca521114e88d:0xdf492f8f4a0a30a6!2m2!1d-95.431535!2d29.9904035?entry=ttu'},
  { id: 14, name: 'Break Time Market', lat: 29.69170777989032, lng: -95.41723310206818, address: '7901 S Main St Houston, Texas , 77025', src: `${logo}`, loc: 'https://www.google.com/maps/dir//7901+S+Main+St,+Houston,+TX+77030,+USA/@29.7063022,-95.4050845,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640c06d929ce2fb:0xac0b40ce932377d0!2m2!1d-95.4050845!2d29.7063022?entry=ttu'},
  { id: 15, name: 'Briar Forest Site #1', lat: 29.756468601020952, lng: -95.64393769120576, address: '1585 HWY 6 South Houston, Texas , 77077', src: `${logo}`, loc: 'https://www.google.com/maps/dir//1585+S+Texas+6,+Houston,+TX+77077,+USA/@29.7562544,-95.6439055,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dc018feaf1df:0xc2261623ebadb4d9!2m2!1d-95.6439055!2d29.7562544?entry=ttu'},
  { id: 16, name: 'ECO Travel Plaza', lat: 35.978055017196795, lng: -85.01555831789787, address: '1897 Genesis Rd. Crossville, TN, 38555', src: `${logo}`, loc: 'https://www.google.com/maps/dir//1897+Genesis+Rd,+Crossville,+TN+38555,+USA/@35.9778814,-85.0155905,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88675751aa971155:0x4347440228809287!2m2!1d-85.0155905!2d35.9778814?entry=ttu'},
  { id: 17, name: 'Eldridge Site #8', lat: 29.712190985878816, lng: -95.6245872777162, address: '13629 Alief Clodine Rd Houston, Texas , 77082', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13629+Alief+Clodine+Rd,+Houston,+TX+77083,+USA/@29.7120326,-95.6245229,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640ddb58be3e713:0xa8e6b056ffc8009a!2m2!1d-95.6245229!2d29.7120326?entry=ttu'},
  { id: 18, name: 'Flagship Distribution', lat: 29.715571870426345, lng: -95.58285642004365, address: '11601 Alief Clodine Rd Houston, Texas, 77082', src: `${logo}`, loc: 'https://www.google.com/maps/dir//11601+Alief+Clodine+Rd,+Houston,+TX+77082,+USA/@29.7153669,-95.5828135,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640dd1f87ac6bbd:0x669db16f00ab9bf3!2m2!1d-95.5828135!2d29.7153669?entry=ttu'},
  { id: 19, name: 'Galveston Rd Site #12', lat: 29.648066862753062, lng: -95.22184967771891, address: '7450 Galveston Rd Houston, TX, 77034', src: `${logo}`, loc: 'https://www.google.com/maps/dir//7450+Galveston+Rd,+Houston,+TX+77034,+USA/@29.6479177,-95.2217853,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8640982933b0c3cb:0x2b373aa3ee441e!2m2!1d-95.2217853!2d29.6479177?entry=ttu'},
  { id: 20, name: 'Gyro King', lat: 29.545566883052274, lng: -95.13452076052525, address: '528 Bay Area Blvd Ste 600 Webster, TX, 77598', src: `${logo}`, loc: 'https://www.google.com/maps/dir//Surgical+Specialists+of+Clear+Lake,+450+W.+Medical+Center+Blvd+Suite+600,+Webster,+TX+77598,+United+States/@29.5441049,-95.1367911,15z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x86409cf362ec78ed:0xe47567443fc72ba1!2m2!1d-95.1265724!2d29.5418342?entry=ttu'},
  { id: 21, name: 'Gyro King', lat: 29.699836441439157, lng: -95.40130697771671, address: '7131 Fannin St Houston, Texas, 77030', src: `${logo}`, loc: 'https://www.google.com/maps/dir//7131+Fannin+St,+Houston,+TX+77030,+USA/@29.6997364,-95.4836449,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640c00cf9bd74c7:0xf9175c1f731d7139!2m2!1d-95.4012426!2d29.6996128?entry=ttu'},
  { id: 22, name: 'Gyro King', lat: 29.595593069577173, lng: -95.61961198935931, address: '2587 Town Centre Blvd, N Sugarland, Texas, 77479', src: `${logo}`, loc: 'https://www.google.com/maps/dir//2587+Town+Center+Blvd+N,+Sugar+Land,+TX+77479,+USA/@29.5955583,-95.7020572,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640e694071cd5a3:0xc04f5450852016a5!2m2!1d-95.6196549!2d29.5954345?entry=ttu'},
  { id: 23, name: 'Gyro King', lat: 29.924942752047087, lng: -95.59949810469007, address: '12020 FM-1960 Rd W Houston, Texas, 77065', src: `${logo}`, loc: 'https://www.google.com/maps/dir//12020+Farm+to+Market+1960+Rd+W,+Houston,+TX+77065,+USA/@29.9249265,-95.6819004,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640d19f5f476767:0x2aee0a57d9c9f043!2m2!1d-95.5994981!2d29.9248033?entry=ttu'},
  { id: 24, name: 'Gyro King', lat: 29.679136972974604, lng: -95.64140910470049, address: '9203 Texas 6 #110 Houston, Texas, 77083', src: `${logo}`, loc: 'https://www.google.com/maps/dir//9203+S+Texas+6+Suite+%23110,+Houston,+TX+77083,+USA/@29.6789997,-95.7238114,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640ddfc9132d6bb:0xb5ef50e336348340!2m2!1d-95.6414091!2d29.678876?entry=ttu'},
  { id: 25, name: 'Gyro King', lat: 29.69848040751366, lng: -95.38756927586351, address: '2424 Old Spanish Trail Houston, Texas, 77054', src: `${logo}`, loc: 'https://www.google.com/maps/dir//2424+Old+Spanish+Trl,+Houston,+TX+77054,+USA/@29.6984456,-95.4699823,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640bff3eae3596f:0x4cef5ca98e82b9f4!2m2!1d-95.38758!2d29.698322?entry=ttu'},
  { id: 26, name: 'Gyro King 1', lat: 29.784423671748428, lng: -95.70277539120461, address: '19111 Katy Freeway, Ste 700 Houston, Texas, 77094', src: `${logo}`, loc: 'https://www.google.com/maps/dir//19111+Katy+Fwy+%23700,+Houston,+TX+77094,+USA/@29.7843888,-95.7851455,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640d8e99a371b47:0x16126952978829dd!2m2!1d-95.7027432!2d29.7842654?entry=ttu'},
  { id: 27, name: 'HWY 90 - Brand Lane', lat: 29.624481988345405, lng: -95.57669510470282, address: '4133 South Main St Stafford, Texas , 77477', src: `${logo}`, loc: 'https://www.google.com/maps/dir//4133+S+Main+St,+Stafford,+TX+77477,+USA/@29.624354,-95.6590974,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640e63369c740c3:0x62865ad9781e622e!2m2!1d-95.5766951!2d29.6242302?entry=ttu'},
  { id: 28, name: 'Inez', lat: 30.913610864505614, lng: -94.82654291999198, address: '16261 US HWY 59 N Inez, Texas , 77698', src: `${logo}`, loc: 'https://www.google.com/maps/dir//16261+US-59,+Moscow,+TX+75960,+USA/@30.9134406,-94.8290749,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x86386416a704b9eb:0xd1ea16c08631ed62!2m2!1d-94.8265!2d30.913436?entry=ttu'},
  { id: 29, name: 'Liquor Forever Site', lat: 29.68147407661405, lng: -95.62269874702802, address: '13646 Bissonnet St. STE A Houston, Texas , 77083', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13646+Bissonnet+St+a,+Houston,+TX+77083,+USA/@29.6814207,-95.7051225,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640dd8d7d207219:0x5030766cf3dbad1b!2m2!1d-95.6227202!2d29.681297?entry=ttu'},
  { id: 30, name: 'Now & Forever 59', lat: 29.691304839059587, lng: -95.52659024702764, address: '8107 Southwest FWY Houston, Texas , 77074', src: `${logo}`, loc: 'https://www.google.com/maps/dir//8107+Southwest+Fwy,+Houston,+TX+77074,+USA/@29.6912607,-95.609014,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640c28e9e77b2e9:0x26f762fc6434344c!2m2!1d-95.5266117!2d29.6911371?entry=ttu'},
  { id: 31, name: 'Now & Forever @ Beechnut', lat: 29.689234801680062, lng: -95.65175056237244, address: '15051 Beechnut St Houston, Texas, 77083', src: `${logo}`, loc: 'https://www.google.com/maps/dir//15051+Beechnut+St,+Houston,+TX+77083,+USA/@29.6892093,-95.7341314,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640de0c8862056b:0xeea91c3cafc8cd5e!2m2!1d-95.6517291!2d29.6890857?entry=ttu'},
  { id: 32, name: 'Now & Forever @ Beechnut # 1', lat: 29.689163803915918, lng: -95.62279913353626, address: '13600 Beechnut St Houston, Texas, 77083', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13600+Beechnut+St,+Houston,+TX+77083,+USA/@29.6889727,-95.6253633,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640dd94f256d777:0x94911286e5225a1!2m2!1d-95.6227884!2d29.6889681?entry=ttu'},
  { id: 33, name: 'Now & Forever @ Dairy Ashford', lat: 29.638619902307916, lng: -95.59744376237461, address: '12507 Dairy Ashford Stafford, Texas, 77477', src: `${logo}`, loc: 'https://www.google.com/maps/dir//12507+Dairy+Ashford+Rd,+Stafford,+TX+77477,+USA/@29.6385478,-95.6798246,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640e7a5c63eca3f:0x9456d2971eca8a54!2m2!1d-95.5974223!2d29.6384241?entry=ttu'},
  { id: 34, name: 'Now & Forever @ Kirkwood', lat: 29.658672763289925, lng: -95.58290554702901, address: '11660 West Bellfort Ave Houston, Texas, 77099', src: `${logo}`, loc: 'https://www.google.com/maps/dir//11660+W+Bellfort+Ave,+Houston,+TX+77099,+USA/@29.6584816,-95.5855019,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640e792ca5a7b59:0x60137e1e1ce88a66!2m2!1d-95.582927!2d29.658477?entry=ttu'},
  { id: 35, name: 'Now & Forever @ Lake Houston', lat: 30.00890421238842, lng: -95.14502747585033, address: '8751 FM 1960 Rd Humble, Texas , 77346', src: `${logo}`, loc: 'https://www.google.com/maps/dir//8751+Farm+to+Market+1960+Rd+E,+Humble,+TX+77346,+USA/@30.0086487,-95.1476131,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640ac7f10f9e38d:0x2cb7f3914eeb73d!2m2!1d-95.1450382!2d30.0086441?entry=ttu'},
  { id: 36, name: 'Now & Forever Southwest Freeway', lat: 29.71895589884526, lng: -95.49783070469883, address: '6500 Southwest FWY Houston, Texas, 77074', src: `${logo}`, loc: 'https://www.google.com/maps/dir//6500+Southwest+Fwy,+Houston,+TX+77074,+USA/@29.7188559,-95.580233,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640c235769616cf:0x5c79636ff1191df8!2m2!1d-95.4978307!2d29.7187323?entry=ttu'},
  { id: 37, name: 'PLUS (Highway 6)', lat: 29.735293930130645, lng: -95.64321127586194, address: '14531 Westheimer Rd STE A Houston, Texas , 77077', src: `${logo}`, loc: 'https://www.google.com/maps/dir//14531+Westheimer+Rd+a,+Houston,+TX+77082,+USA/@29.7352125,-95.7256243,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640dc20fe076247:0x9adbacdfef93d6ec!2m2!1d-95.643222!2d29.735089?entry=ttu'},
  { id: 38, name: 'S. Main', lat: 29.6238385780935, lng: -95.57722262004758, address: '4223 South Main St Stafford, Texas , 77477', src: `${logo}`, loc: 'https://www.google.com/maps/dir//4223+S+Main+St,+Stafford,+TX+77477,+USA/@29.6237852,-95.659582,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640e63363871b8b:0xa461095500707072!2m2!1d-95.5771797!2d29.6236614?entry=ttu'},
  // { id: 39, name: 'Sealy', lat: 0, lng: 0, address: '7642 I-10 Sealy, Texas, 77474', src: `${logo}`, loc: ''},
  { id: 40, name: 'Seawall Blvd/Galveston', lat: 29.299757239673923, lng: -94.77697236053554, address: '1102 Seawall Blvd Galveston, Texas, 77550', src: `${logo}`, loc: 'https://www.google.com/maps/dir//1102+Seawall+Blvd,+Galveston,+TX+77550,+USA/@29.2995187,-94.7796009,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x863f9e4fb516d467:0xffbf5e500d2442a6!2m2!1d-94.777026!2d29.299514?entry=ttu'},
  { id: 41, name: 'Shepherd', lat: 29.778497281121666, lng: -95.408872202843, address: '4740 Katy Freeway Houston, Texas , 77007', src: `${logo}`, loc: 'https://www.google.com/maps/dir//4740+Katy+Fwy,+Houston,+TX+77007,+USA/@29.7784625,-95.4913496,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640c74eb815ef53:0x75bec57a4c192e54!2m2!1d-95.4089473!2d29.778339?entry=ttu'},
  { id: 42, name: 'Shepherd', lat: 29.778191593762312, lng: -95.40910011818774, address: '4740 Katy Freeway Service Rd Houston, TX , 77007', src: `${logo}`, loc: 'https://www.google.com/maps/dir//4740+Katy+Freeway+Service+Road,+Houston,+TX+77007,+USA/@29.7780286,-95.4117072,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640c74eb134f801:0xed6065d8300a0772!2m2!1d-95.4091323!2d29.778024?entry=ttu'},
  { id: 43, name: 'Steak n Shake @ Bissonnet', lat: 29.681567287802974, lng: -95.62267728935568, address: '13646 Bissonnet St Houston, Texas , 77083', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13646+Bissonnet+St,+Houston,+TX+77083,+USA/@29.6814207,-95.7051225,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640dd8d7d207219:0x70628f392323864b!2m2!1d-95.6227202!2d29.681297?entry=ttu'},
  { id: 44, name: 'Steak n Shake @ Briar Forest', lat: 29.756505857659857, lng: -95.64391623353342, address: '1585 HWY 6 South Houston, Texas , 77077', src: `${logo}`, loc: 'https://www.google.com/maps/dir//1585+S+Texas+6,+Houston,+TX+77077,+USA/@29.7563779,-95.7263078,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640dc018feaf1df:0xc2261623ebadb4d9!2m2!1d-95.6439055!2d29.7562544?entry=ttu'},
  { id: 45, name: 'Steak n Shake @ Westheimer', lat: 29.7355583328666, lng: -95.63464264702577, address: '13905 Westheimer Rd Houston, TX, 77077', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13905+Westheimer+Rd,+Houston,+TX+77077,+USA/@29.7355328,-95.7170664,12z/data=!3m2!4b1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640dc3cd0b8a473:0xfd14dfb9703a9fc!2m2!1d-95.6346641!2d29.7354093?entry=ttu'},
  { id: 46, name: 'Synott', lat: 29.662602486972716, lng: -95.61743866237356, address: '13350 West Bellfort Sugarland, Texas, 77498', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13350+W+Bellfort,+Sugar+Land,+TX+77498,+USA/@29.6625584,-95.6998195,12z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640e7645b0f83cd:0xf59f11b0dac5afb6!2m2!1d-95.6174172!2d29.6624347?entry=ttu'},
  // { id: 47, name: 'Texas Forever Bar & Grill', lat: 0, lng: 0, address: '17124 - B E Freeway Service Rd Channelview, Texas, 77530', src: `${logo}`, loc: ''},
  { id: 48, name: 'Texas Forever Channelview/Warehouse', lat: 29.790732527724657, lng: -95.09280407771287, address: '17109 East FWY Channelview, Texas, 77530', src: `${logo}`, loc: 'https://www.google.com/maps/dir//17109+E+Freeway+Service+Rd,+Channelview,+TX+77530,+USA/@29.790523,-95.0953146,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640a72e71175993:0xbc7a4535179b887c!2m2!1d-95.0927397!2d29.7905184?entry=ttu'},
  { id: 49, name: 'W-Belfort & Synott', lat: 29.66009389390698, lng: -95.59488823716786, address: '13380 W Bellfort Ave Houston, Texas', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13380+W+Bellfort+Ave,+Stafford,+TX+77477,+USA/@29.659899,-95.5974588,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640e78535a5ea17:0x156f581b0e34b7d4!2m2!1d-95.5948839!2d29.6598944?entry=ttu'},
  { id: 50, name: 'Westheimer HWY-6', lat: 29.735337110772043, lng: -95.6432672893534, address: '14537 Westheimer Rd Houston, Texas , 77077', src: `${logo}`, loc: 'https://www.google.com/maps/dir//14537+Westheimer+Rd,+Houston,+TX+77077,+USA/@29.7350902,-95.6458851,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640dc2055e05671:0x12805aa66bcca1c2!2m2!1d-95.6433102!2d29.7350856?entry=ttu'},
  { id: 51, name: 'Wind Chase', lat: 29.736579895447374, lng: -95.62799324702573, address: '13450 Westheimer Rd. Houston, Texas , 77077', src: `${logo}`, loc: 'https://www.google.com/maps/dir//13450+Westheimer+Rd,+Houston,+TX+77077,+USA/@29.7363982,-95.6305896,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640dc3e575b44b7:0x28dcee468c04e4ad!2m2!1d-95.6280147!2d29.7363936?entry=ttu'},
  { id: 52, name: 'Winkleman Apartment', lat: 29.688913220375508, lng: -95.6559483200448, address: '15309 Beechnut St Houston, Texas , 77083', src: ``, loc: 'https://www.google.com/maps/dir//15309+Beechnut+St,+Houston,+TX+77083,+USA/@29.688806,-95.6584803,17z/data=!3m1!5s0x86409c8dc8168b5b:0x49c72aecf6e95cf!4m8!4m7!1m0!1m5!1m1!1s0x8640de0d70dd4d69:0xe06d944632eacd25!2m2!1d-95.6559054!2d29.6888014?entry=ttu'},
];
