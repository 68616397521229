import procedure1 from "../../assets/Home/procedure (1).svg"
import procedure2 from "../../assets/Home/procedure (2).svg"
import procedure3 from "../../assets/Home/procedure (3).svg"
import procedure4 from "../../assets/Home/procedure (4).svg"

export const info = [
    {
        src: `${procedure1}`,
        heading: "What we are",
        text: "Now and Forever is a premier chain of gas stations located across Houston."
    },
    {
        src: `${procedure4}`,
        heading: "Active Sites",
        text: "Across Houston and Counting!"
    },
    {
        src: `${procedure3}`,
        heading: "Houstonians Employed",
        text: "and Counting!"
    },
    {
        src: `${procedure2}`,
        heading: "Truck Stops",
        text: "And counting!"
    },
];    