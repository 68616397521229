export const articleData2 = [
    { heading: "Charcoal", text: "For a superior hookah experience, try our Coco Nara charcoal. Known for its clean and long-lasting burn, it's perfect for enhancing your sessions." },
    { heading: "Hookah Flavors", text: "Elevate your hookah experience with our diverse range of flavors. Choose from Grape, Mint, Lemon, Watermelon, Peach, and Mango to find your favorite taste sensatio." },
    { heading: "Vapes", text: "Our extensive vape selection includes popular brands and flavors to suit every preference. Explore options like Pulse Pop, Geek Bar, Juicy Bar (including Juicy Pro, Juicy Max, and ALL JUICY varieties), Nexa, and Air Bar Stick in Spearmint, Watermelon Ice, and Strawberry Ice. We also offer premium choices from North and Chris Brown.." },
    { heading: "Vape Juice", text: "Complement your vaping with our top-notch vape juices. Coastal Clouds and Naked offer rich, flavorful options to enhance your vaping sessions.." },
    { heading: "Delta 8 Products", text: "Experience the benefits of Delta 8 with our curated selection. Brands like Cake, Jeeters, STNR, and Medosa provide a smooth and enjoyable Delta 8 experience.." },
    { heading: "THC Products", text: "For those seeking potent and reliable effects, our THC products from HAPPI and Dr. Dank are perfect for both recreational and medicinal use." },
    { heading: "Kratom", text: "Enhance your well-being with our high-quality Kratom offerings. Try Hush Kratom NANO and Mit 45 for their known quality and effectiveness.." },
    { heading: "Gummies", text: "Enjoy a tasty and discreet way to consume cannabinoids with our selection of gummies from Cake, Stizzy, and Tree House. Perfect for any occasion.." },
    { heading: "Wraps", text: "Roll your own with our variety of wraps. Choose from CAMO, RAW, Blazy Susan, and Zigzag for the perfect smoking experience.Whether you're looking for premium charcoal, diverse hookah flavors, a variety of vapes and vape juices, or top-quality Delta 8 and THC products, Now and Forever has you covered. Explore our collection today and find your new favorites!." },
  ];

 


