import mc from '../../assets/Services/mc.png'
import krunchy from '../../assets/Services/krunchy.png'
import shipley from '../../assets/Services/shipley.png'
import shipley_d from '../../assets/Services/shipley_d.png'
import flava_wings from '../../assets/Services/flava_wings.png'
import hotline_b from '../../assets/Services/hotline_b.png'
import louigiana from '../../assets/Services/louigiana.png'
import dq from '../../assets/Services/dq.png'

export const Restaurant_Partners = [
    {
        src: `${mc}`,
        head: "Mcdonalds",
        para1: "- Located next to Now & Forever Westheimer Parkway",
        para2: "- 25645 Westheimer Pkwy, Katy, TX 77494"
    },
    {
        src: `${krunchy}`,
        head: "Krispy Crunchy Chicken",
        para1: "- Located next to Now & Forever Beechnut 1",
        para2: "- 13600 Beechnut St, Houston, TX 77083"
    },
    {
        src: `${shipley}`,
        head: "Shipleys",
        para1: "- Located next to Now & Forever Bissonnet",
        para2: "- 13646 Bissonnet St, Houston, TX 77083"
    },
    {
        src: `${flava_wings}`,
        head: "Flava Wings",
        para1: "- Located next to Now & Forever Bissonnet",
        para2: "- 13646 Bissonnet St, Houston, TX 77083"
    },
    {
        src: `${hotline_b}`,
        head: "Hotline Burger",
        para1: "- Located next to Now & Forever Briar Forest",
        para2: "- 1585 S Texas 6, Houston, TX 77077"
    },
    {
        src: `${louigiana}`,
        head: "Louisiana Fried Chicken",
        para1: "- Located next to Now & Forever Beechnut #2",
        para2: "- 15051 Beechnut St, Houston, TX 77083"
    },
    {
        src: `${dq}`,
        head: "Dairy Queen",
        para1: "- Located next to Eco Travel Plaza (Tenessee)",
        para2: "- 1897 Genesis Rd, Crossville, TN 38555"
    },
    {
        src: `${shipley_d}`,
        head: "Shipleys",
        para1: "- Located next to Now & Forever Bissonnet",
        para2: "- 13646 Bissonnet St, Houston, TX 77083"
    },
]