import sliderimage1 from "../../assets/Home/home-page-banner-1.c5a50263693ef5e3346c.webp"
import sliderimage2 from "../../assets/Home/home-page-banner-2.webp";
import sliderimage3 from "../../assets/Home/home-page-banner-3.webp";
import sliderimage4 from "../../assets/Home/home-page-banner-4.webp"
import sliderimage5 from "../../assets/Home/home-page-banner-5.webp"

// import sliderimage4 from "../../assets/Home/";
// import sliderimage5 from "../../assets/Home/home-page-banner-5.jpg";





export const Slider = [
    {
        id:"1", 
        src: `${sliderimage1}`,
      
    },
    {
        src: `${sliderimage2}`,
        id:"2",
    },
    {   
        id:"3",
        src: `${sliderimage3}`,
     
    },
    {   
        id:"4",
        src: `${sliderimage5}`,
     
    }, 
    {
        id:"5",
        src: `${sliderimage4}`,
     
    },
];
