import regular_unleaded from "../../assets/Services/5.1.png"
import super_unleaded from "../../assets/Services/4.png"
import desiel from "../../assets/Services/3.png"
import desiel2 from "../../assets/Services/2.png"
import clear_diesel from "../../assets/Services/1.png"



export const fuel_service = [
    {
        src: `${regular_unleaded}`,
        head: "Regular Unleaded",
        text1: " Octane Rating: 87",
        text2: " Ethanol Percentage: Up to 10%",
        text3: " Suitable for most gasoline-powered vehicles.",
        text4: " Helps reduce greenhouse gas emissions and fossil fuel dependence.",
        text5: " Provides standard engine performance and fuel efficiency.",
        dashed: "1"
    },
    {
        src: `${super_unleaded}`,
        head: "Super Unleaded",
        text1: " Octane Rating: Typically around 91 or 93",
        text2: " Ethanol Percentage: Up to 10%",
        text3: " Ideal for high-performance vehicles or those requiring higher octane levels.",
        text4: " Prevents engine knock or pinging, optimizing engine performance.",
        text5: " Offers smoother acceleration and improved throttle response.",
        dashed: "1"
    },
    {
        src: `${desiel}`,
        head: "Diesel",
        text1: " Contains up to 5% biodiesel (B5)",
        text2: " Reduces greenhouse gas emissions",
        text3: " Compatible with most modern diesel engines",
        text4: " Provides similar performance to conventional diesel fuel.",
        text5: " Helps lower dependence on fossil fuels.",
        dashed: "1"
    },
    {
        src: `${desiel2}`,
        head: "Diesel",
        text1: " Contains up to 20% biodiesel (B20) ",
        text2: " Offers greater environmental benefits",
        text3: " Compatible with most modern diesel engines",
        text4: " Similar performance and efficiency to conventional diesel fuel.",
        text5: " Reduces greenhouse gas emissions and promotes sustainability.",
        dashed: "1"
    },
    {
        src: `${clear_diesel}`,
        head: "Clear Diesel",
        text1: " Low-sulfur diesel option, containing significantly lower sulfur levels.",
        text2: " Helps reduce harmful emissions such as sulfur dioxide and nitrogen oxides.",
        text3: " Contributes to cleaner air and better public health.",
        text4: " Preserves engine components and prolongs engine life.",
        dashed: "1"
    },
];