import coke from "../../assets/Products/SoftDrinks/coke.png"
import dew from "../../assets/Products/SoftDrinks/dew.png"
import fanta from "../../assets/Products/SoftDrinks/fanta.png"
import monster from "../../assets/Products/SoftDrinks/monster.png"
import pepsi from "../../assets/Products/SoftDrinks/pepsi.png"
import sprite from "../../assets/Products/SoftDrinks/sprite.png"

export const ProductsCountList = [
    {

        items: [
            {
                img: `${coke}`,
            },
            {
                img: `${sprite}`
            },
            {
                img: `${fanta}`
            },
            {
                img: `${pepsi}`
            },
            {
                img: `${dew}`
            },
            {
                img: `${monster}`
            }
        ]
    },
    {
        
        items: [
            {
                img: `${coke}`,
            },
            {
                img: `${sprite}`
            },
            {
                img: `${fanta}`
            },
            {
                img: `${pepsi}`
            },
            {
                img: `${dew}`
            },
           
        ]
    },
    {
        
        items: [
            {
                img: `${coke}`,
            },
            {
                img: `${sprite}`
            },
            {
                img: `${fanta}`
            },
            {
                img: `${pepsi}`
            }
           
        ]
    },
    {
        
        items: [
            {
                img: `${coke}`,
            },
            {
                img: `${sprite}`
            },
            {
                img: `${fanta}`
            }
           
        ]
    },
    {
        
        items: [
            {
                img: `${coke}`,
            },
            {
                img: `${sprite}`
            }
           
        ]
    },
    {
        
        items: [
            {
                img: `${coke}`,
            }
           
        ]
    },
    {
        
        items: [
            {
                img: `${coke}`,
            }
           
        ]
    },
    {
        
        items: [
            {
                img: `${coke}`,
            }
           
        ]
    },
    {
        
        items: [
            {
                img: `${coke}`,
            }
           
        ]
    },
    {
        
        items: [
            {
                img: `${coke}`,
            }
           
        ]
    }


];
