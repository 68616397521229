import spirits from "../../assets/Products/spirits.png"
import tobacco from "../../assets/Products/tobacco.png"
import snacks from "../../assets/Products/snacks.png"
import candy from "../../assets/Products/candy.png"
import softdrinks from "../../assets/Products/softdrinks.png"
import energydrinks from "../../assets/Products/enerydrink.png"
import merch from "../../assets/Products/merch.png"
import bottledwater from "../../assets/Products/bottledwater.png"
import health from "../../assets/Products/health.png"
import smokeshop from "../../assets/Products/smokeshop.png"

export const ProductsList = [
  {
    img: `${spirits}`,
    index:0,
    head: "SPIRITS",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "https://www.doordash.com/convenience/store/28073144/category/alcohol-1024",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas.",
  },
  {
    img: `${tobacco}`,
    index:1,
    head: "TOBACCO",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "/tobacco",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas."
  },
  {
    img: `${snacks}`,
    index:2,
    head: "SNACKS",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "https://www.doordash.com/convenience/store/28073144/category/snacks-758",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas."
  },
  {
    img: `${candy}`,
    index:3,
    head: "CANDY",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "https://www.doordash.com/convenience/store/28073144/category/candy-750",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas."
  },
  {
    img: `${softdrinks}`,
    index:4,
    head: "SOFT DRINKS",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "https://www.doordash.com/convenience/store/28073144/category/drinks-751/sub-category/soda-810/?sortBy=unspecified",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas."
  },
  {
    img: `${energydrinks}`,
    index:5,
    head: "ENERGY DRINK",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "https://www.doordash.com/convenience/store/28073144/category/drinks-751/sub-category/energy-1408/?sortBy=unspecified",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas."
  },
  {
    img: `${merch}`,
    index:6,
    head: "MERCH",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "/merch",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas."
  },
  {
    img: `${bottledwater}`,
    index:7,
    head: "BOTTLED WATER",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "https://www.doordash.com/convenience/store/28073144/category/drinks-751/sub-category/still%20water-1545/?sortBy=unspecified",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas."
  },
  {
    img: `${health}`,
    index:8,
    head: "HEALTH",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "https://www.doordash.com/convenience/store/28073144/category/medicine-756",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas."
  },
  {
    img: `${smokeshop}`,
    index:9,
    head: "SMOKE SHOP",
    para: "Now and Forever is the chain of multiple gas stations across Houston.",
    url: "/smoke-shop",
    paragraph: "Now and Forever is the chain of multiple gas stations across Houston, Texas. Currently, we have several gas stations in Houston texas."
  },
];
