export const life = [
    {
        id: "id_1",
        heading: "Paid Time Off: 2 weeks Annually",
        text: "Now & Forever is the global leader in convenience, with a rich history and even brighter future. We understand that our people drive our success, —"
    },
    {
        id: "id_2",
        heading: "401k Plan",
        text: "Now & Forever is the global leader in convenience, with a rich history and even brighter future. We understand that our people drive our success, —"
    },
    {
        id: "id_3",
        heading: "Fringe Benefits",
        text: "Now & Forever is the global leader in convenience, with a rich history and even brighter future. We understand that our people drive our success, —"
    },
    {
        id: "id_4",
        heading: "Employee Welfare & Training",
        text: "Now & Forever is the global leader in convenience, with a rich history and even brighter future. We understand that our people drive our success, —"
    },
    {
        id: "id_5",
        heading: "Mental Health Days",
        text: "Now & Forever is the global leader in convenience, with a rich history and even brighter future. We understand that our people drive our success, —"
    },
    {
        id: "id_6",
        heading: "Overtime Benefits",
        text: "Now & Forever is the global leader in convenience, with a rich history and even brighter future. We understand that our people drive our success, —"
    },
    {
        id: "id_7",
        heading: "Bonuses Twice a Year",
        text: "Now & Forever is the global leader in convenience, with a rich history and even brighter future. We understand that our people drive our success, —"
    },
    {
        id: "id_8",
        heading: " Bulletproof Cashier's Cabins",
        text: "Now & Forever is the global leader in convenience, with a rich history and even brighter future. We understand that our people drive our success, —"
    },
];