import img1 from "../../assets/News/Article3img1.png"
import img2 from "../../assets/News/Article3img2.png"
import img3 from "../../assets/News/Article3img3.png"
import img4 from "../../assets/News/Article3img4.png"



export  const Article3Data = [
    { image: `${img1}`, description: "We made it easy for our customers to participate: just spend $20, jot down your details on the receipt, and drop it in the designated box. The excitement was contagious, and we were thrilled to see an astonishing 250 entries pour in!." },
    { image: `${img2}`, description: `This Mother's Day, Now and Forever turned up the fun with our special "Mother Loving Giveaway," delightfully dubbed "Tanks for Moms."`},
    { image: `${img3}`, description: "From these entries, 22 lucky winners were selected to receive a free full tank of gas. It was a joy to see the smiles on their faces as they got their tanks filled up on us. This giveaway was our heartfelt way of appreciating all the amazing moms out there, making their day brighter and their journeys smoother.." },
    { image: `${img4}`, description: "The success of this event was beyond our expectations, and it truly showcased the spirit of our community. We are grateful to everyone who participated and helped make this giveaway a memorable one.." },
  ];