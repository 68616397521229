import React from 'react'
import "./Section02.css"
import whoweImage2 from "../../../../assets/News/Article4.png"

const Section02 = () => {
    return (
        <>

            {/* <div className='d-flex flex-column-reverse flex-lg-row align-items-center px-2 px-lg-4 mx-auto aboutsecondBanner-art4-sec2'>
                <div>
                    <img className='img-fluid w-100 h-100' src={whoweImage2} alt="" draggable='false' />
                </div>
                <div className='d-flex flex-column align-items-center align-items-lg-end justify-content-center mb-5 mb-lg-0'>
                    <div>

                        <p className='pb-3'>At Now and Forever, our commitment to the Houston community extends far beyond our chain of gas stations. This summer, we've proudly partnered with the Sugar Land Space Cowboys, bringing our brand's spirit to the ballpark and creating memorable experiences for fans and families alike.</p>

                    </div>
                </div>
            </div> */}
          <div className='para-container' >
            <p className='para'> At Now and Forever, our commitment to the Houston community extends far beyond our chain of gas stations. This summer, we've proudly partnered with the Sugar Land Space Cowboys, bringing our brand's spirit to the ballpark and creating memorable experiences for fans and families alike.</p>
          </div>




              

        </>
    )
}

export default Section02