import img1 from "../../assets/News/Article1111.png"
import img2 from "../../assets/News/Article1122.png"
import img3 from "../../assets/News/Article1133.png"
import img4 from "../../assets/News/Article1144.png"



export const Article11Data = [
    {
      
      image: `${img1}`,
      text:`MONSTER:` ,
        texttwo:`2 for $5 (16 oz)` ,
        textthree:`` ,
    },
    {
     
      image: `${img2}`,
      text: "CORE WATER ",
      texttwo:`C4: 2 for $5` ,
      textthree:`CELCIUS: 2 for $5` ,
    },
    {
     
      image: `${img3}`,
      text:'Free Ice Bag with the ' ,
      texttwo:`purchase of` ,
      textthree:`Two Cases of Beer!` ,
    },
    {
        image: `${img4}`,
        text: " PEPSI: ",
        texttwo:`2 for $4 on all Pepsi flavors,` ,
      textthree:`Mountain Dew & Starry (20 oz)` ,
      },
  ];