import React from "react";
import "./Section02.css"
import img1 from "../../../../assets/Articles/Article14-img-1.png"
import img2 from "../../../../assets/Articles/Article14-img-2.png"
import img3 from "../../../../assets/Articles/Article14-img-3.png"
import img4 from "../../../../assets/Articles/Article14-img-4.png"
import img5 from "../../../../assets/Articles/Article14-img-5.png"
import img6 from "../../../../assets/Articles/Article14-img-6.png"
import img7 from "../../../../assets/Articles/Article14-img-7.png"
import img8 from "../../../../assets/Articles/Article14-img-8.png"
import img9 from "../../../../assets/Articles/Article14-img-9.png"
import img10 from "../../../../assets/Articles/Article14-img-10.png"
import img11 from "../../../../assets/Articles/Article14-img-11.png"
import img12 from "../../../../assets/Articles/Article14-img-12.png"



const Section02 = () => {
    return <>
    <div className="Main-container-art14-sec2">
        <div className="container-art14-sec2">
            <h1 className="art14-sec2-h1" >The Bull Bags: $12.99</h1>
            <p className="p-art14-sec2" > Introducing the "Bull Belt Bag" from Now and Forever! This sleek, all-black pouch is designed with both styleand functionality in mind. Featuring a convenient half-zip, it opens to reveal a spacious main compartment along with three see-through mini pockets, perfect for keeping your essentials organized and easily accessible. Adorning it’s right side is an adorable drawing of one of our signature bulls,adding a unique and charming touch to your everyday carry.Belt bags, or fanny packs, offer hands-free convenience, making it easy to carry your phone, wallet, and keys securely. Whether you're running errands or exploring, the Bull Belt Bag combines practicality with style, ensuring your essentials are always within reach.</p>
            <div className="images-art14-sec2" >
                <img src={img1} alt="Image 1" />
                <img src={img2} alt="Image 1" />
                <img src={img3} alt="Image 1" />
            </div>
        </div>
        </div>
        <div className="Main-container-art14-sec2">
        <div className="container-art14-sec2">
            <h1 className="art14-sec2-h1" >N&F Beanies: $6.99</h1>
            <p className="p-art14-sec2" >Next up is our cozy N&F Beanies, available in a variety of colors: Black, Red, Blue, Maroon, and Green. Each beanie features a playful design with our three signature bulls looking adorably chilly. Perfect for keeping warm and stylish, these beanies are a must-have accessory for any cold-weather wardrobe.</p>
            <div className="images-art14-sec2" >
                <img src={img4} alt="Image 1" />
                <img src={img5} alt="Image 1" />
                <img src={img6} alt="Image 1" />
            </div>
        </div>
        </div>
        <div className="Main-container-art14-sec2">
        <div className="container-art14-sec2">
            <h1 className="art14-sec2-h1" >N&F Car Air Fresheners: $1.39</h1>
            <p className="p-art14-sec2" >Introducing our N&F Car Air Fresheners, available in a variety of delightful scents: New Car, Orange, Rose, Cologne, Black Ice, Squash, Pine, Vanilla, Cherry, Light Coffee, and Dark Coffee. Choose from an array of fun designs, including zodiac signs, coffee-themed options for caffeine lovers, and many more unique Now and Forever Brand styles. Freshen up your ride with a scent and design that suits your personality!</p>
            <div className="images-art14-sec2" >
                <img src={img7} alt="Image 1" />
                <img src={img8} alt="Image 1" />
                <img src={img9} alt="Image 1" />
            </div>
        </div>
        </div>
        <div className="Main-container-art14-sec2">
        <div className="container-art14-sec2">
            <h1 className="art14-sec2-h1" >Valentine's Day “LOVE TRUCK” White T-shirts: $8.99</h1>
            <p className="p-art14-sec2" >Celebrate love with our versatile “Love Truck”, white T-shirts! Featuring one of our adorable bull mascots in a pink truck, these casual white tees are perfect for any occasion. While they make an excellent Valentine’s Day gift, they’re also a charming way to show someone you care any time of the year. Comfortable and stylish, our “Love Truck” T-shirts are a delightful addition to anyone’s wardrobe. </p>
            <div className="images-art14-sec2" >
                <img src={img10} alt="Image 1" />
                <img src={img11} alt="Image 1" />
                <img src={img12} alt="Image 1" />
            </div>
        </div>
        </div>
        <div className="Main-container-art14-sec2">
        <div className="container-art14-sec2">
            <h1 className="art14-sec2-h1" >Valentine's Day “TE QUIERO MOOCHO”-Black T-shirts: $8.99</h1>
            <p className="p-art14-sec2" >Introducing our Valentine's Day Te Quiero Moocho - Black T-shirts! These black tees feature a cute bull graphic on the front right and a playful text bubble on the back that says "Te Quiero Moocho." Perfect for spreading love and smiles, these shirts are a fun and unique way to show affection. Ideal for gifting or treating yourself, our Te Quiero Moocho T-shirts combine comfort and charm, making them a standout piece for Valentine's Day and beyond.  </p>
         </div>
        </div>

    </>;
}

export default Section02;