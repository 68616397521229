import React from 'react'
import "./Section01.css"

const Section01 = () => {
  return (
    <>
      <div className='d-flex align-items-center justify-content-center privacySection_1'>
        <h1 className='text-white'>Privacy Policy</h1>
      </div>
      <div className='p-3 p-md-5 count-text mx-auto' style={{maxWidth: "1200px"}}>
        <h5>Your Responsibilities</h5>
        <p>While we are committed to protecting your privacy, it's essential for you to be aware of your responsibilities when using our Site. Please ensure that any Personal Information you provide to us is accurate, complete, and up-to-date. Additionally, it's crucial to maintain the security of your account credentials and not to share them with others.</p>
        <p>By using our Site, you agree to:</p>
        <ol>
          <li>Provide accurate and truthful information when prompted.</li>
          <li>Keep your account information, including passwords, secure and confidential.</li>
          <li>Notify us immediately if you become aware of any unauthorized access to your account or any other security breach.</li>
        </ol>
        <h5>Data Processing and Usage</h5>
        <p>We may process and use your Personal Information for various purposes, including but not limited to:</p>
        <ol>
          <li>Providing, maintaining, and improving our Site and services.</li>
          <li>Personalizing your experience on our Site.</li>
          <li>Communicating with you, including responding to your inquiries and providing customer support.</li>
          <li>Analyzing trends, usage patterns, and preferences to enhance our services.</li>
          <li>Complying with legal obligations and resolving disputes.</li>
        </ol>
        <p>We may also aggregate and anonymize your Personal Information for statistical or research purposes, in which case it will no longer be considered Personal Information.</p>
        <h5>Data Sharing and Disclosure</h5>
        <p>We may share your Personal Information with third-party service providers, contractors, and affiliated organizations that assist us in operating our Site, conducting our business, or serving you. These third parties are contractually obligated to use your Personal Information only as necessary to provide the services requested by us and to adhere to the same level of privacy protection as stated in this Privacy Policy.</p>
        <p>We may also disclose your Personal Information in the following circumstances:</p>
        <ol>
          <li>To comply with applicable laws, regulations, or legal processes.</li>
          <li>To respond to lawful requests from public authorities, including national security or law enforcement requests.</li>
          <li>To protect our rights, property, or safety, as well as those of our users or others.</li>
        </ol>
        <p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your Personal Information may be transferred as part of the transaction. You will be notified via email and/or a prominent notice on our Site of any change in ownership or uses of your Personal Information, as well as any choices you may have regarding your Personal Information.</p>
        <h5>Data Protection Rights</h5>
        <p>You may have certain rights regarding your Personal Information under applicable data protection laws. These rights may include:</p>
        <ol>
          <li>The right to access, update, or delete your Personal Information.</li>
          <li>The right to restrict or object to the processing of your Personal Information.</li>
          <li>The right to data portability, allowing you to obtain a copy of your Personal Information in a structured, machine-readable format.</li>
        </ol>
        <p>If you wish to exercise any of these rights or have any questions about our data practices, please contact us using the contact information provided at the end of this Privacy Policy.</p>
        <h5>Data Security Measures</h5>
        <p>We take the security of your Personal Information seriously and employ industry-standard security measures to protect it from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, access controls, and regular security audits.</p>
        <p>However, please be aware that no method of transmission over the Internet or electronic storage is completely secure. Therefore, while we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute secu</p>
        <h5>Children's Online Privacy Protection</h5>
        <p>Protecting the privacy of children is especially important to us. Our Site is not directed to individuals under the age of 13, and we do not knowingly collect Personal Information from children under 13. If you are a parent or guardian and believe that your child has provided us with Personal Information, please contact us immediately. If we become aware that we have collected Personal Information from children without verification of parental consent, we will take steps to remove that information from our servers.</p>
        <h5>Changes to This Privacy Policy</h5>
        <p>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the revised Privacy Policy on this page, with the "Last updated" date reflecting the most recent revision. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
        <h5>Contact Us</h5>
        <p>If you have any questions, concerns, or comments about this Privacy Policy, our data practices, or your dealings with our Site, please contact us at [Your Contact Email].</p>
      </div>
    </>
  )
}

export default Section01