import news1 from "../../assets/News/moos-news-art-img1.png"
import news2 from "../../assets/News/moos-news-art-img2.png"
import news3 from "../../assets/News/moos-news-art-img3.png"
import news4 from "../../assets/News/articl4-main-img1.png"
import news5 from "../../assets/News/moos-newa-art-img5.png"
import news6 from "../../assets/News/moos-new-art-img6.png"


export const news = [
    {
        src: `${news1}`,
        url : "/Article14",
        heading: "Explore Now and Forever'sExclusive Merch!",
        text:"Author: Staff Reports",
    },
    {
        src: `${news2}`,
        url : "/Article2",
        heading: "Smoke Shop Finds at Now and Forever", 
        text:"Author: Staff Reports",
        
    },
    {
        src: `${news3}`,
        url : "/Article3",
        heading: " Tanks a Lot, Moms! Our Mother's Day Giveaway Was a Hit!  ",
        text:"Author: Staff Reports",
        
    },
    {
        src: `${news4}`,
        url : "/Article4",
        heading: "Now and Forever's Winning Partnership With the Sugar Land Space Cowboys ",
        text:"Author: Staff Reports",
        
    },
    {
        src: `${news5}`,
        url : "/Article6",
        heading: "Now and Forever Expand in Huston: New store Openings for 2024",
        text:"Author: Staff Reports",
        
    },
    {
        src: `${news6}`,
        url : "/Article11",
        heading: "Deals Now saving forever N&F’s Best Deals all Year Long!",
        text:"Author: Staff Reports",
        
    },
    // {
    //     src: `${news1}`,
    //     heading: "Daily News",
    //    
    // },
    // {
    //     src: `${news1}`,
    //     heading: "",
    //     text: "Now and Forever is the chain of multiple gas stations across Houston, Texas."
    // },
    // {
    //     src: `${news1}`,
    //     heading: "Daily News",
    //     text: "Now and Forever is the chain of multiple gas stations across Houston, Texas."
    // },
    // {
    //     src: `${news1}`,
    //     heading: "Daily News",
    //     text: "Now and Forever is the chain of multiple gas stations across Houston, Texas."
    // },
    // {
    //     src: `${news1}`,
    //     heading: "Daily News",
    //     text: "Now and Forever is the chain of multiple gas stations across Houston, Texas."
    // },
    // {
    //     src: `${news1}`,
    //     heading: "Daily News",
    //     text: "Now and Forever is the chain of multiple gas stations across Houston, Texas."
    // },
    
];