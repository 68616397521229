import merchandise_1 from "../../assets/Merch/delta 8 cake.png"
import merchandise_2 from "../../assets/Merch/gummies cake.png"
import merchandise_3 from "../../assets/Merch/coconara.png"
import merchandise_4 from "../../assets/Merch/Hookah flavours.png"
import merchandise_5 from "../../assets/Merch/kraton.png"
import merchandise_6 from "../../assets/Merch/prerolled.png"
import merchandise_7 from "../../assets/Merch/thc happi.png"
import merchandise_8 from "../../assets/Merch/Vape jucies.png"
import merchandise_9 from "../../assets/Merch/vapes.png"
import merchandise_10 from "../../assets/Merch/wraps.png"
export const merchandise_data = [
    {
        img: `${merchandise_3}`,
        title: "Charcoal",
        url: "https://nowandforever.com/product/its-boo-not-moo-tee/"
    },
    {
        img: `${merchandise_1}`,
        title: "Delta 8",
        price: "$19.99.",
        url: "https://nowandforever.com/product/te-quiero-moooo-cho-mug/"
    },
    {
        img: `${merchandise_2}`,
        title: "Gummies",
        price: "$19.99.",
        url: "https://nowandforever.com/product/te-quiero-moooo-cho-mug/"
    },
    {
        img: `${merchandise_4}`,
        title: "Hookah Flavours",
        price: "$19.99.",
        url: "https://nowandforever.com/product/te-quiero-moooo-cho-mug/"
    },
    {
        img: `${merchandise_5}`,
        title: "Kraton",
        price: "$19.99.",
        url: "https://nowandforever.com/product/te-quiero-moooo-cho-mug/"
    },
    {
        img: `${merchandise_6}`,
        title: "PREROLLS - BLAZE",
        price: "$19.99.",
        url: "https://nowandforever.com/product/te-quiero-moooo-cho-mug/"
    },
    {
        img: `${merchandise_7}`,
        title: "THC",
        price: "$19.99.",
        url: "https://nowandforever.com/product/te-quiero-moooo-cho-mug/"
    },
    {
        img: `${merchandise_8}`,
        title: "Vape Jucies",
        price: "$19.99.",
        url: "https://nowandforever.com/product/te-quiero-moooo-cho-mug/"
    },
    {
        img: `${merchandise_9}`,
        title: "Vapes",
        price: "$19.99.",
        url: "https://nowandforever.com/product/te-quiero-moooo-cho-mug/"
    },
    {
        img: `${merchandise_10}`,
        title: "WRAP",
        price: "$19.99.",
        url: "https://nowandforever.com/product/te-quiero-moooo-cho-mug/"
    },
];