import explorepic1 from "../../assets/Services/promotion-img-1.png"
import explorepic2 from "../../assets/Services/explorepic2.png"
import explorepic3 from "../../assets/Services/promotion-img-2.png"

export const promotions = [
    {
        src: `${explorepic1}`,
        heading: "Find Our Stores",
        // text: "Now and Forever is a premier chain of gas stations located across Houston.",
        // btn_text: "Explore more"
    },
    {
        src: `${explorepic2}`,
        heading: "Our Offers",
        // text: "Now and Forever is a premier chain of gas stations located across Houston.",
        // btn_text: "Explore more"
    },
    {
        src: `${explorepic3}`,
        heading: "Now & Forever Delivers!",
        // text: "Now and Forever is a premier chain of gas stations located across Houston.",
        // btn_text: "Explore more"
    },
];    