import React from 'react'

const Section05 = () => {
    return (
        <>
            <div style={{ margin: "10px 0" }}>
                <video width="100%" height="100%" muted loop autoPlay>
                    <source src="AboutUsVideo.mp4" type="video/mp4" />
                </video>
            </div>
        </>
    )
}

export default Section05